import timg1 from '../images/team/team_member_image_1.webp'
import timg2 from '../images/team/team_member_image_2.webp'
//import timg3 from '../images/team/team_member_image_3.webp'
// import timg4 from '../images/team/team_member_image_4.webp'
// import timg5 from '../images/team/team_member_image_5.webp'


const Teams = [
   {
      Id: '1',
      tImg: timg1,
      name: 'OM JI PAL ',
      slug: 'Om-Ji-Pal',
      title: 'CFO (Consultant)',
   },
   {
      Id: '2',
      tImg: timg2,
      name: 'VINAY SINGH',
      slug: 'Vinay-Singh',
      title: 'CEO (Software Engineer)',
  
   },
   // {
   //    Id: '3',
   //    tImg: timg3,
   //    name: 'SURAJ SINGH',
   //    slug: 'Suraj-Singh',
   //    title: 'CMO (Marketing Head)',
  
   // }
   
]

export default Teams;